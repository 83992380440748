import React, { Component } from 'react';
import BaseRoot from 'components/BaseRoot';
import RelatedSelection from "./BaseRoot/RelatedSelection";

export default class Root extends Component {

    static getTemplates() {
        return Object.assign(BaseRoot.getTemplates(), {
            "related_selection": RelatedSelection

        });
    }

    render() {
        return <BaseRoot {...this.props} getTemplates={Root.getTemplates}/>
    }
}