import React, { Component } from 'react';

import BaseEntities from 'components/BaseEntities';
import PublicationTile from 'components/PublicationTile';
import PublicationList from 'components/PublicationList';
import PollList from 'components/Entities/PollList';


export default class Entities extends Component {

    static getTemplates() {
        return Object.assign(BaseEntities.getTemplates(), {
            "publication_tile": PublicationTile,
            "publication_list": PublicationList,
            "poll_list": PollList,
        });
    }

    render() {
        return <BaseEntities {...this.props} getTemplates={Entities.getTemplates}/>;
    }
}
