import React, { Component } from 'react';
import {
  Panel, ListGroup,
  ListGroupItem,
  Button, Row, Col,
  ProgressBar
} from 'react-bootstrap';


export default class PublicationList extends Component {
  render() {
    const { items, actions, loading, descriptions, meta } = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;

    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
          <PollListItem key={i} data={child} actions={actions} descriptions={descriptions} position={i} meta={meta}/>
        )}
      </div>
    );
  }
}


class PollListItem extends Component {
  componentWillMount() {
    this.setState({
      curQuestionIdx: 0,
      questions: this.props.data.extra.questions
    });
  }

  getPollProgress() {
    const { questions, curQuestionIdx } = this.state;
    return curQuestionIdx / (questions.length - 1) * 100;
  }

  prevQuestion() {
    const { curQuestionIdx } = this.state;
    if (curQuestionIdx > 0) {
      this.setState({curQuestionIdx: curQuestionIdx - 1});
    }
  }

  nextQuestion() {
    const { questions, curQuestionIdx } = this.state;
    if (curQuestionIdx < questions.length - 1) {
      this.setState({curQuestionIdx: curQuestionIdx + 1});
    }
  }

  render() {
    const pollName = this.props.data.entity_name;
    const pollUrl = this.props.data.extra.url || this.props.data.entity_url;
    const isTaken = this.props.data.extra.is_taken;
    const { questions, curQuestionIdx } = this.state;
    let question = null;

    let body = null;
    let footer = null;

    if (questions.length > 0) {
      question = questions[curQuestionIdx];

      let totalVotes = 0;
      for (const answer of question.answers)
        totalVotes += answer.votes;

      body = (
        <Panel.Body>
          <p>{question.text}</p>
          <ListGroup>
          {question.answers.map((answer, key) => {
            const percentage = totalVotes ? answer.votes / totalVotes * 100 : 0;
            return (
              <ListGroupItem key={key}>
                {answer.text ? answer.text : "Свой вариант"}
                <ProgressBar now={percentage}
                  label={percentage ? `${parseFloat(percentage.toFixed(1))}%` : ''}/>
              </ListGroupItem>
            );
          })}
          </ListGroup>
        </Panel.Body>
      );
    }

    if (questions.length > 1) {
      const isFirstQuestion = curQuestionIdx == 0,
            isLastQuestion = curQuestionIdx == questions.length - 1;
      footer = (
        <Panel.Footer className="text-center">
          <Row className="show-grid">
            <Col sm={4} xs={12}>
              <Button bsStyle="warning"
                      block disabled={isFirstQuestion}
                      onClick={() => this.prevQuestion()}>
                Предыдущий вопрос
              </Button>
            </Col>
            <Col sm={4} smOffset={4} xs={12}>
              <Button bsStyle="info"
                      block disabled={isLastQuestion}
                      onClick={() => this.nextQuestion()}>
                Следующий вопрос
              </Button>
            </Col>
          </Row>
        </Panel.Footer>
      );
    }

    let pollProgress = null;
    if (questions.length > 1)
      pollProgress = <ProgressBar now={this.getPollProgress()} className="progress-bar-info"/>;

    const ret = (
      <Panel>
        <Panel.Heading>
          <Panel.Title className="text-center">
            <h3 className="text-center">
              {pollName} 
            </h3>
            <p>
              <a href={pollUrl}>{isTaken ? "Ссылка на результаты" : "Пройти опрос"}</a>
            </p>
          </Panel.Title>
          {pollProgress}
        </Panel.Heading>
        {body}
        {footer}
      </Panel>
    );
    return ret;
  }
}
