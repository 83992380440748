import React from 'react';
import { render } from 'react-dom';
import configureStore from 'store/configureStore';
import Singleton from 'utils/singleton';

import Root from './components/Root';
import ReactWeatherWidget from './react-weather-widget';
import PollWidget from './poll';

const globalStore = Singleton.getInstance();

const data_marts = document.getElementsByClassName('ex-data-mart'),
      weather_widget = document.getElementById('weather-widget'),
      poll_containers = document.getElementsByClassName('poll-container');


for (let i = data_marts.length - 1; i >= 0; i--) {
  const data_mart = data_marts[i],
        store = configureStore(),
        dom_attrs = data_mart.attributes;

  let alias = dom_attrs.getNamedItem('data-store-alias');
  if (!alias) {
    const mart_id = dom_attrs.getNamedItem('data-selected-entry-point-id');
    if (mart_id)
      alias = `data_mart_${mart_id.value}`;
  } else
    alias = alias.value;
  globalStore[alias] = store;

  render(
      <Root store={store} dom_attrs={dom_attrs} />,
      data_mart
  );
}


if (weather_widget) {
    render(<ReactWeatherWidget />, weather_widget);
}


for(const elem of poll_containers){
  render(<PollWidget pollId={elem.getAttribute('data-poll-id')}/>, elem);
}
