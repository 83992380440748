import React, { Component } from 'react'
import cookie from 'react-cookies'


export default class WeatherWidget extends Component {

    componentWillMount() {
        const data = JSON.parse(document.getElementById('weather-widget').getAttribute('data-weather'));
        if (!data.weatherList.length)
            throw new Error('Weather list is empty!');

        let selectedWeatherIdx = 0;

        const weatherID = parseInt(cookie.load('weatherID'));
        if (weatherID) {
            const idx = data.weatherList.findIndex(weather => weather.id === weatherID);

            if (idx !== -1)
                selectedWeatherIdx = idx;
        }

        this.setState({
            selectedWeatherIdx,
            weatherList: data.weatherList,
        });
    }

    selectWeather(idx) {
        const { weatherList } = this.state;

        this.setState({
            selectedWeatherIdx: idx
        });

        cookie.save('weatherID', weatherList[idx].id, { path: '/' });
    }

    render() {
        const { selectedWeatherIdx, weatherList } = this.state;
        const selectedWeather = weatherList[selectedWeatherIdx];

        return (
            weatherList.length > 1 ?
            <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown">
                    <span className="weather-name">{selectedWeather.name}</span>&nbsp;&nbsp;<i className={`wi-day-${selectedWeather.cloudiness}-${selectedWeather.precipitation}`} />&nbsp;&nbsp;<span className="weather-temp">{selectedWeather.temperature}</span><sup>°</sup>С&nbsp;<span className="caret" />
                </button>
                <ul className="dropdown-menu">
                    {
                        weatherList.map((weather, idx) => weather.id !== selectedWeather.id && <li key={idx}><a href="#" onClick={() => this.selectWeather(idx)}>{weather.name}</a></li>)
                    }
                </ul>
            </div>
            :
            <div className="weather-box">
                <span className="weather-name">{selectedWeather.name}</span>&nbsp;&nbsp;<i className={`wi-day-${selectedWeather.cloudiness}-${selectedWeather.precipitation}`} />&nbsp;&nbsp;<span className="weather-temp">{selectedWeather.temperature}</span><sup>°</sup>С
            </div>
        );
    }
}
